







































































































import { ref, watch, useRouter, useContext } from '@nuxtjs/composition-api';
import { SfPrice, SfImage, SfLoader } from '@storefront-ui/vue';
import { useIsMobile } from '@/helpers/mobileDevices';
import { ParentSku } from '~/repository/getParentSkuByChild.repository';
import InputQuantity from './InputQuantity.vue';
const __sfc_main = {};
__sfc_main.props = {
  image: {
    type: String,
    default: ''
  },
  sku: {
    type: String,
    default: ''
  },
  imageWidth: {
    type: [Number, String],
    default: 115
  },
  title: {
    type: String,
    default: ''
  },
  regularPrice: {
    type: [Number, String],
    default: null
  },
  specialPrice: {
    type: [Number, String],
    default: null
  },
  newPrice: {
    type: Number,
    default: undefined
  },
  qty: {
    type: [Number, String],
    default: 1
  },
  minQty: {
    type: Number,
    default: null
  },
  maxQty: {
    type: Number,
    default: null
  },
  link: {
    type: [String, Object],
    default: null
  },
  hasRemove: {
    type: Boolean,
    default: true
  },
  hasMoreActions: {
    type: Boolean,
    default: true
  },
  confirmDeletion: {
    type: Boolean,
    default: false
  },
  typename: {
    type: String,
    default: null
  },
  isInStock: {
    type: String,
    default: null
  },
  withoutCart: {
    type: Boolean,
    default: true,
    required: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    app
  } = useContext();
  const props = __props;
  const emit = __ctx.emit;
  const router = useRouter();
  const {
    isMobile
  } = useIsMobile();
  const loadingRedirectPictureClick = ref(false);
  const confirmDelete = ref<Boolean>(props.confirmDeletion);
  function handleQuantityChange(newQty: number) {
    emit('input', newQty);
  }
  const calculateDiscount = (initialAmount: number, reducedAmount: number): number => {
    if (initialAmount <= 0 || reducedAmount > initialAmount) {
      throw new Error("Les valeurs ne sont pas valides.");
    }
    return (initialAmount - reducedAmount) / initialAmount * 100;
  };
  function removeHandler() {
    emit('click:remove');
    confirmDelete.value = false;
  }
  const navigateToProductDetail = async (click: String) => {
    const sku = props.sku;
    const typename = props.typename;
    const link = props.link;
    click === 'picture' ? props.loadingRedirectPictureClick = true : props.loadingRedirectNameClick = true;
    let path: string = "";
    if (typename !== 'ConfigurableProduct') {
      path = `/p/${sku}/${link}`;
      click === 'picture' ? props.loadingRedirectPictureClick = false : props.loadingRedirectNameClick = false;
    } else {
      const skuByChild = new ParentSku();
      const productParent = await skuByChild.getParentSkuByChild(sku);
      if (productParent.data.length > 0) {
        path = `/p/${productParent.data[0].sku}/${link}`;
      } else {
        path = `/p/${sku}/${link}`;
      }
      click === 'picture' ? props.loadingRedirectPictureClick = false : props.loadingRedirectNameClick = false;
    }
    const redirectProduct = app.localePath(path);
    await router.push(redirectProduct);
  };
  return {
    props,
    isMobile,
    loadingRedirectPictureClick,
    handleQuantityChange,
    calculateDiscount,
    removeHandler,
    navigateToProductDetail
  };
};
__sfc_main.components = Object.assign({
  SfLoader,
  SfImage,
  SfPrice,
  InputQuantity
}, __sfc_main.components);
export default __sfc_main;
